import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/SchoolProgram/2023/Valediction_ceremony/1.jpg';
import p2 from 'assests/Photos/SchoolProgram/2023/Valediction_ceremony/2.jpg';
import p3 from 'assests/Photos/SchoolProgram/2023/Valediction_ceremony/3.jpg';
import p4 from 'assests/Photos/SchoolProgram/2023/Valediction_ceremony/4.jpg';
import p5 from 'assests/Photos/SchoolProgram/2023/Valediction_ceremony/5.jpg';
// import p6 from 'assests/Photos/SchoolProgram/2023/RepublicDay/6.jpg';
// import p7 from 'assests/Photos/SchoolProgram/2023/RepublicDay/7.jpg';
// import p8 from 'assests/Photos/SchoolProgram/2023/RepublicDay/8.jpg';
// import p9 from 'assests/Photos/SchoolProgram/2023/RepublicDay/9.jpg';
// import p10 from 'assests/Photos/SchoolProgram/2023/RepublicDay/10.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';

const Valedictionceremony2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const photos = [
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
          src: p5,
          source: p5,
          rows: 1,
          cols: 1,
      },
        {
            src: p3,
            source: p3,
            rows: 1.5,
            cols: 2,
        }, 
       
//       {
//           src: p6,
//           source: p6,
//           rows: 1,
//           cols: 1,
//       },
//       {
//           src: p7,
//           source: p7,
//           rows: 1,
//           cols: 1,
//       },
//       {
//           src: p8,
//           source: p8,
//           rows: 1,
//           cols: 1,
//       }, 
//       {
//           src: p9,
//           source: p9,
//           rows: 1,
//           cols: 1,
//       },
//       {
//           src: p10,
//           source: p10,
//           rows: 1,
//           cols: 1,
//       }, 
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                VALEDICTION CEREMONY 	
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                               Class: 9-12 Date: 18 JANUARY 2024
                                </Typography>
                                <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “If you believe in yourself and have dedication and determination and never quit, you'll be a winner. The price of victory is high but so are the rewards.”

                                <br />
                            </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The Annual Prize Distribution Ceremony is the day to recognise students’ efforts in academics, inter-house competitions and sport events. The Valedictory Function for classes 9-12 was held at National Public School, Yeshwanthpur on 18 January 2024 to applaud and appreciate the efforts of the students. It was the time to reward the meritorious students for their academic excellence and enthusiastic participation in various events held during the Academic Session 2023-24. 
                                    <br></br>
                                    The event commenced with march past by the cabinet members. They were awarded the certificates for their outstanding contribution in the efficient and effective functioning of the school. The cabinet members handed over the responsibility to their counterparts. Thereafter, the winners of the various Association Activities like Literary, Science, Commerce, Maths, Humanities and Cyber were felicitated for their remarkable performances. The certificates were conferred upon the winners of various Sport Events like Throw ball, Basketball, Table Tennis, Badminton and Chess. 
                                    <br></br>
                                    The Principal Ms Sheeja Manoj appreciated the cabinet members for their exemplary service, exceptional leadership and genuine commitment to the institution. She encouraged the achievers and congratulated them for their sterling performance in all the events. She also motivated the students to do better in future.
                                </Typography>
                            </Box>
                            {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “As we raise our flags high, let’s remember the responsibility that comes with the privilege of being an Indian. ”

                                <br />
                            </Typography> */}

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2023/>
                        </Box> 
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Valedictionceremony2023;